/* Root Variables */
:root {
  --primary-color: #791626;
  --white-color: #f8f7f7;
  --black-color: #212121;
  --navy-color: #04101A;
  --grey-color: #D9D9D9;
  --pink-color: #C62E51;
  --dark-grey: #5b5959;
  --hover-grey: #5a5657;
  --nav-bg: #464344;
}

/* Base Styles */
html, body {
  margin: 0;
  padding: 0;
  font-family: 'Helvetica', sans-serif;
  height: 100%;
  background: var(--navy-color);
  color: var(--white-color);
}

/* Layout */
.gallery-container {
  display: flex;
  min-height: 100vh;
  background-color: var(--navy-color); /* Extends background */
}

.sidebar {
  width: 15%;
  background-color: var(--black-color);
  color: var(--white-color);
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.tag-button {
  padding: 8px 12px;
  border: none;
  border-radius: 500px;
  background-color: var(--nav-bg);
  color: var(--white-color);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.tag-button.active {
  background-color: var(--primary-color);
}

.tag-button:hover {
  background-color: var(--pink-color);
  color: #fff;
  transform: scale(1.05);
}

.nav-item:hover {
  background-color: var(--hover-grey);
  color: var(--white-color);
}

/* Similarly for the "Open in New Tab" button in the modal: */
.gallery-modal-open-tab:hover {
  background-color: var(--hover-grey);
  color: #fff;
}

/* Gallery Content */
.gallery-content {
  flex-grow: 1;
  padding: 20px;
  color: var(--white-color);
}

.gallery-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 2rem;
  margin-bottom: 20px;
}

.year-title {
  cursor: pointer;
  color: var(--white-color);
  font-size: 1.5rem;
  margin: 10px 0;
  transition: color 0.3s;
}

.year-title:hover {
  color: #d33c5f; /* Lighter hover effect */
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 15px;
}

.gallery-item {
  position: relative;
  transition: opacity 0.3s ease;
}

.gallery-item.dimmed {
  opacity: 0.1; /* Dim effect */
}

.gallery-thumbnail {
  width: 100%;
  border: 2px solid var(--grey-color);
  border-radius: 5px;
  transition: transform 0.3s ease, border-color 0.3s ease;
}

.gallery-thumbnail:hover {
  transform: scale(1.05);
  border-color: var(--primary-color);
}

/* Modal */
.gallery-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.gallery-modal-content {
  background: var(--black-color);
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
}

.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  color: white;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 5;
}

.modal-top {
  display: flex;
  gap: 20px;
}

.gallery-modal-image {
  flex: 1;
  max-width: 100%;
  max-height: 60vh;
  object-fit: contain;
  border: 2px solid var(--primary-color);
}

.modal-metadata {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.modal-metadata h3 {
  font-size: 1.5rem;
}

.modal-metadata p {
  max-height: 200px;
  overflow-y: auto;
}

.modal-actions {
  display: flex;
  gap: 10px;
}

.gallery-modal-download, .gallery-modal-open-tab {
  padding: 10px 20px;
  border-radius: 20px;
  text-decoration: none;
  color: var(--white-color);
  transition: background-color 0.3s ease;
}

.gallery-modal-download {
  background-color: var(--primary-color);
}

.gallery-modal-download:hover {
  background-color: #d33c5f;
}

.gallery-modal-open-tab {
  background-color: var(--black-color);
}

/* Tags */
.modal-tag {
  display: inline-block;
  margin: 5px;
  padding: 10px 15px;
  background: var(--nav-bg);
  color: white;
  border-radius: 50px;
  transition: background-color 0.3s;
}

.modal-tag:hover {
  background-color: var(--primary-color);
}

/* Controls */
.gallery-controls {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.gallery-controls button {
  padding: 8px 12px;
  background-color: var(--primary-color);
  border: none;
  border-radius: 20px;
  color: var(--white-color);
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.gallery-controls button:hover {
  background-color: #d33c5f;
}

/* Responsive Design */
@media (max-width: 768px) {
  .layout {
    flex-direction: column;
  }

  .sidebar {
    width: 60%;
    gap: 10px;
  }

  .gallery-grid {
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    gap: 10px;
  }

  .modal-top {
    flex-direction: column;
    align-items: center;
  }

  .gallery-modal-image {
    max-height: 40vh;
  }
}
/* Wider construction border for PENDING tag */
.pending-border img.gallery-thumbnail {
  border: 3px solid transparent;
  border-image: repeating-linear-gradient(
    45deg,
    #FCA311,
    #FCA311 10px,
    #A8201A 10px,
    #A8201A 20px
  );
  border-image-slice: 1;
  padding: 2px; /* To ensure spacing between border and content */
}

.modal-description {
  max-height: 200px;
  overflow-y: auto;
}
